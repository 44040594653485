@import "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css";

.editor-fix img, .editor-fix svg {
  vertical-align: top !important;
}

.editor-fix [role=textbox] {
  padding: 1em !important;
}

.editor-fix [data-slate-node=element] {
  margin-top: 0;
  margin-bottom: 0;
}

.card-green {
  border-color: #d6e9c6 !important;
}

.card-green .card-header {
  background-image: linear-gradient(to bottom,#dff0d8 0,#d0e9c6 100%);
  color: #3c763d;
  border-color: #d6e9c6 !important;
}

.card-blue {
  border-color: #bce8f1;
}

.card-blue .card-header {
  background-image: linear-gradient(to bottom,#d9edf7 0,#c4e3f3 100%);
  color: #31708f;
  border-color: #bce8f1;
}
